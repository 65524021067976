import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Testing the Authors Component`}</h1>
    <h5>{`This is a stress test for the Authors Component`}</h5>
    <p><strong parentName="p">{`NOTE: To see dark/light mode variants click the moon/sun in the header`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      